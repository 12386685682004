//
// -- indicators (loading bars, progress bars, callouts and the like)

@keyframes loading-bar-spinner {
	0% {
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}

//
// -- charts

//@import 'compass';
@import 'vars';

// styles

.chart-container {
	background:white;
	color:#333;
	padding:10px;
	text-align: center;
	.styled-select, .input-group {
		margin: 10px 0;
	}
	.chart-background {
		height:350px;
		.chart {
			background-color:#fff;
			margin: 0 0 12px 0;
		}
	}
	.chart-selector {
		background-color:#fff; 
		color:#666; 
		padding:20px 10px;
		text-align:center;
		span {
			margin:0 10px;
		}
	}
}
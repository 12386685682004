/*** MODALS ***/
.modal-backdrop {
    background-color: #5f5f5f;
}
.modal-content {
    background-color: #000;
    padding: 10px 15px;
    border-radius: 0;
}

.modal-header {
    border-bottom: none;
    padding: 20px 15px 5px;
}

.modal-footer {
    border-top: none;
    padding: 5px 15px 15px
}

.modal-header h3 {
    margin-top: 6px;
}

/* FULL SCREEN MODAL */
.full-screen.modal-dialog {
    width: 100%;
    margin: 0;
}

.full-screen .modal-content {
    min-height: 700px;
    height: 100%;
}

.full-screen iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 610px;
    /* border-radius: 0; */
    /* box-shadow: none; */
}

/* general modal skin */

.modal .btn-close {
    font-size: 30px;
    color: #fff;
    float: right;
    line-height: 1;
    background-color: transparent;
    border:none;
    cursor: pointer;
}

.padding-0{
    padding-right: 0;
    padding-left: 0;
}

.top-buffer { margin-top:20px; }

.modal.fade.in {
    display: block;
}

@media(min-width:768px) {
    .modal-content {
        padding: 10px 20px;
    }
}

/**** TYPOGRAPHY ****/

/* Generated by Glyphter (http://www.glyphter.com) on  Mon Jan 18 2016*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Glyphter.woff') format('woff'),
    url('../fonts/Glyphter.ttf') format('truetype'),
    url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*='icon-']:not(.glyphicon):before {
    display: inline-block;
    font-family: 'Glyphter';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-Campaigns_Icon:before {
    content: '\0041';
}

.icon-Dashboard_Icon:before {
    content: '\0042';
}

.icon-Home_Icon:before {
    content: '\0043';
}

.icon-MyAccount_Icon:before {
    content: '\0044';
}

.icon-Reports_Icon:before {
    content: '\0045';
}

.icon-Correspondence_Icon:before {
    content: '\0046';
}

.icon-Subscribers_Icon:before {
    content: '\0047';
}

.icon-Libraries_Icon:before {
    content: '\0048';
}

.icon-JourneyBuilder_Icon:before {
    content: '\0049';
}

#loading-bar .bar {
    background-color:white;
}

a {
    color: #fff;
}

a:hover,
a:focus,
a:active {
    color: #fff;
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
    font-family: 'Droid Serif', serif;
    font-weight: 700;
    font-style: italic;
}

hr {
    border-top:2px solid rgba(255,255,255,0.3);
    margin: 15px 0;
}

.font-sm {

}

.font-md {
    font-size: 16px
}

.font-lg {
    font-size: 36px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
    padding-left: 15px;
    padding-right: 15px;
}

.page-header {
    margin:20px 0;
}

/* Custom page header */
.header {
    border-bottom: 1px solid #e5e5e5;
}

/* Version */
.app-version {
    position: absolute;
    left: 66px;
    bottom: -0.5rem;
    color: #808080;
    font-size: 8px;
    letter-spacing: 2px;
}

/* Make the masthead heading the same height as the navigation */
.header h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
}

/* Custom page footer */
.footer {
    padding-top: 19px;
    color: #777;
    border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
    margin: 30px 0;
}


.keyword-tag {
    background-color: #000;
    border: 2px solid #535353;
    padding: 2px 5px;
    margin: 0 10px 10px 0;
    text-align: center;
    font-weight: 400;
    color: #FFF;
    height: 40px;
    vertical-align: middle;
    display: inline-block;
    line-height: 35px;
    position: relative;
    min-width: 40px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.fullwidth .keyword-tag {
    display: flex;
    text-align: left;
}

.fullwidth .keyword-tag span:first-child {
    flex: auto;
}

.fullwidth .keyword-tag .delete-icon {
    margin-top: 7px;
}

.keyword-tag-fixed{
    color:#535353;
    opacity:0.7;
}

.keyword-tag-new {
    cursor: pointer;
}

.keyword-tag-new:hover {
    color: #535353;
}

.keyword-column {
    margin:-11px 0 -21px;
}

.delete-icon {
    font-size: 16px;
    margin-left: 5px;
}

/**
  FORMS
*/
.form-group {
    margin-bottom: 10px;
}
.form-control {
    border-radius: 0;
    height: 40px;
    border: 0 none;
    color: #555;
    font-size:15px;
    line-height: 28px;
    width: 100% !important;
}
.form-control[disabled], .btn[disabled] {
    opacity: 0.7;
    background-color: #fff;
}

.form-control.email-preview {
    height:auto;
    min-height: 40px;
}

.form-control.calendar {
    color: #555;
    background-color: #fff;
    border: 1px solid #fff;
}

.form-border {
    padding: 15px 0 15px 0;
    border: 2px solid #535353;
}

.checkbox-inline input[type=checkbox] {
    margin-top: 7px;
}

input[type=radio] {
    margin-top:13px;
}

.input-group-btn .btn-default,
.input-group-btn .btn-default.active:focus,
.input-group-btn .btn-default.active:hover,
.input-group-btn .btn-default:active:focus {
    color: #555;
    background-color: #fff;
    border-color: #fff;
    height: 40px;
}

.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group {
    margin-left: 0;
}

.keyword-input {
    display: inline-block;
}

.keyword-add-button {
    border: 2px solid #fff;
    background-color: #ef3526;
    font-size: 45px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: -4px;
    position: absolute;
    top:0;
    right: 15px;
}

.keyword-add-button:hover,
.keyword-add-button:focus,
.keyword-add-button:active:hover {
    background-color: #b01b20;
}

.error-field, .success-field {
    background: #d2212a;
    color: white;
    padding:6px 13px;
    line-height: 26px;
}

.success-field {
    background: rgba(90,150,10,0.3);
}

.error-warning {
    background: #ddd51c;
}

select {
    background: transparent;
    width: 100%;
    padding: 5px 5px 5px 12px;
    border: 0;
    border-radius: 0;
    font-size: 15px;
    height: 38px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    cursor: pointer;
}

select option:disabled {
    opacity:0.2;
}

select::-ms-expand {
    display: none;
}

select:focus::-ms-value {
    background: transparent;
    color: #000;
}

textarea {
    resize:none;
}

.styled-select {
    height: 40px;
    overflow: hidden;
    background: url(../images/dropdown-bg.png) no-repeat right #fff;
    /*background: #fff;*/
    border: 1px solid #fff;
    color: #555;
    /*position: relative;*/
}

.checkbox-inline, .radio-inline {
    line-height: 26px;
}

/*.styled-select .arrow {
    position: absolute;
    top: 0;
    height: 38px;
    width: 38px;
    right: 0;
    background: url(../images/dropdown-bg.png) no-repeat right #fff;
}*/

.styled-select.disabled, .btn.disabled {
    opacity: 0.7;
}

.styled-select.disabled select {
    cursor:not-allowed;
}

.dropdown-menu {
    padding: 12px;
}

.dropdown-menu thead tr:first-child .btn-default, .dropdown-menu thead tr:first-child .btn-default:active:focus {
    color: #d2212a;
    text-transform: uppercase;
}

.dropdown-menu .btn-info, .dropdown-menu .btn-info:hover, .dropdown-menu .btn-info:focus {
    background: #d2212a;
    box-shadow: none;
    color: #FFF;
}

.dropdown-menu th {
    color:#000;
}

.dropdown-menu table {
    outline-color:white;
}

.dropdown-menu .btn-info .text-info {
    color: #FFF;
}

.ui-select-toggle:hover, .ui-select-toggle:active, .ui-select-toggle:hover:active, .ui-select-toggle:focus, .ui-select-toggle:focus:active {
    background: #FFF;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}

.ui-select-bootstrap .ui-select-choices-row>a:hover {
    background-color: #ddd;
}

.ui-select-bootstrap .ui-select-choices-row.active>a {
    background: #d2212a;
}

.ui-select-match-text {
    line-height: 28px;
}

.ui-select-bootstrap > .ui-select-choices {
    margin-top: 1px;
}

.ui-select-placeholder.text-muted {
    line-height: 28px;
    color: #555;
}

.ui-select-bootstrap .ui-select-toggle > .caret {
    position: absolute;
    height: 100%;
    top: 4px;
    width:37px;
    right: 0;
    background:url(../images/dropdown-bg.png) no-repeat left #fff;
    margin-top: -4px;
    border:0;
}

.btn-default.active:not(.btn-info) {
    background: #FFF;
    box-shadow: none;
    border-color:#000;
}
.btn-default.active:not(.btn-info):hover {
    background:#d4d4d4;
}

.dropdown-menu .text-info {
    color: #333;
}

.dropdown-menu .btn-default:hover {
    border-color:#000;
}

label {
    margin-bottom: 2px;
    line-height:26px;
}

label.label-block {
    display: block;
}

.preview-frame {
    width: 100%;
    border: 0 none;
    background: white;
}


/* BUTTONS */
.btn {
    border-radius: 0;
    border: none;
    -webkit-transition: background-color 300ms ease;
    -moz-transition: background-color 300ms ease;
    -o-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
}

.btn-lg:not(.btn-no-min) {
    min-width: 115px;
}
.btn-lg {
    line-height: 20px;
}

.full-btn {
    width:100%;
}

.btn-primary {
    background-color: #d2212a;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover,
.btn-primary.active, .btn-primary:active,
.open>.dropdown-toggle.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active:hover {
    background-color: #b01b20;
}

.btn-primary.sign-in {
    background-color: #60332e;
}

.btn-primary.sign-in:hover,
.btn-primary.sign-in:focus,
.btn-primary.sign-in:active:hover {
    background-color: #60332e;
}

.btn-secondary {
    font-weight: 400;
    background-color: #d2212a;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active:hover {
    background-color: #b01b20;
    color: #fff;
}

.pagination>.active>a, 
.pagination>.active>a:focus, 
.pagination>.active>a:hover, 
.pagination>.active>span, 
.pagination>.active>span:focus, 
.pagination>.active>span:hover {
    border-color:#d2212a;
    background-color: #d2212a;
}
.pagination>li>a, .pagination>li>span {
    color:#d2212a;
}
.pagination>li>a:focus, 
.pagination>li>a:hover, 
.pagination>li>span:focus, 
.pagination>li>span:hover {
    color:#333;
}


/*1200 - 483*/
/* IMAGES */
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

img.momentum-bg {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
}

/* NEWS SECTION */
.news-section {
    padding-top: 30px;
}

.news-section .sub-heading {
    padding-bottom: 15px
}

/* PANEL */

.panel {
    border:0 none;
    border-radius: 0;
    background-color: transparent;
    margin:10px 0;
}

.dashboard .panel {
    text-align: center;
}

.dashboard .panel-heading {
    font-family: 'Droid Serif', serif;
    font-weight: 700;
    font-style: italic;
    background: #575757;
    border-radius: 0;
    cursor: default;
}

.dashboard .panel-heading .fa {
    padding-right: 10px;
}

.panel-body .sub-heading .custom-bullet {
    float: left;
    padding-right: 10px;
}

.panel-default > .panel-heading, .panel-default .panel-heading-campaign {
    color: #fff;
    background-color: rgba(96, 96, 96,0.5);
    border-color: #606060
}

.analytics-panel > .panel-heading {
    border:1px solid #606060;
}

.analytics-panel > .panel-body {
    height: 450px;
    border-color: #606060;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-radius: 0 0 3px 3px;
}

.panel-default > .panel-heading .fa-wrap, .panel-collapse .widget-body .fa-wrap {
    display: none;
}

.panel-default > .panel-heading:hover .fa-wrap, .panel-collapse .widget-bg:hover .fa-wrap {
    display: inline;
}

.panel-group {
    margin-bottom: 15px;
}

.timeline {
    padding: 20px 0 0;
    list-style: none;
}

.timeline > li {
    margin-bottom: 20px;
}

.timeline-panel {
    border: 2px solid #575757;
    text-align: center;
    padding:20px;
    background:rgba(0,0,0,0.3);
}

/* LOGIN PANEL  */
.login-panel {
    border: none;
}

.login-panel .form-control {
    font-size: 18px;
    text-align: center;
}

* html .login-panel {
    position: absolute;
}

.vertical-align {
    display: table-cell;
}

.vertical-align--middle {
    vertical-align: middle;
}

.table-striped>tbody>tr, .table-striped>tbody>tr:nth-of-type(odd) {
    background-color:rgba(96, 96, 96,0.5);
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 22px 30px;
    line-height: inherit;
    border-top: 0 none;
}

.table {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.login .contact-us a:hover, .login .contact-us a:focus {
    text-decoration: none;
}



/* ICONS */
.fa.fa-circle,
.fa.fa-circle:hover,
.fa.fa-circle:focus {
    color: #606060;
}


.fa-wrap {
    padding: 30px 6px;
}

.glyphicon-calendar:hover,
.glyphicon-calendar:focus,
.glyphicon-calendar:active {
    color: #606060;
}

.glyphicon {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.glyphicon-chevron-left:before {
  content: "\f053";
}
.glyphicon-chevron-right:before {
  content: "\f054";
}

.clock {
    padding: 4px 0 4px 20px;
}


/* BRANDING COLORS */
.primary-color {
    color: #d2212a
}

.secondary-color {
    color: #96BE62
}

.text-muted {
    color: #ccc;
}

.bg-default-color {
    background-color: #606060;
}

.bg-inverse-color {
    background-color: #454545;
}

/* TABLE LISTING */
/*.table.listing {
    border-collapse: separate;
    border-spacing: 0 10px;
}
.table>tbody>tr>td {
    padding: 30px 80px 30px 15px;
    border-top: 2px solid #606060;
    border-bottom: 2px solid #606060;
}
.table>tbody>tr>td .fa {
    display: none;
}
.table>tbody>tr:hover>td .fa {
    display:inline-block;
}
.table>tbody>tr>td:first-child {
    border-left:2px solid #606060;
}
.table>tbody>tr>td:last-child {
    min-width: 80px;
    position: absolute;
    right: 30px;
    border-width: 0;
    padding:30px 15px;
}
.table>tbody>tr>td:nth-last-child(2) {
    border-right:2px solid #606060;
}
.table>tbody>tr {
    background:rgba(96, 96, 96,0.5);
    cursor: pointer;
}
.table>thead>tr>th {
    border:0 none;
}
.table>thead:first-child>tr:first-child>th .table-search {
    max-width: 300px;
    float:right;
}
.table>thead:first-child>tr:first-child>th {
    border-top:2px solid #606060;
    border-bottom:2px solid #606060;
    padding:20px 15px;
}
.table>thead:first-child>tr:first-child>th:first-child {
    border-left:2px solid #606060;
}

.table>thead:first-child>tr:first-child>th:last-child {
    border-right:2px solid #606060;
}*/

.table-header {
    padding: 5px 15px;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
}

.table-header>div {
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ng-table th {
    text-align: left;
}
.ng-table th.sortable .sort-indicator:before, 
.ng-table th.sortable .sort-indicator:after, 
.ng-table th.sortable .sort-indicator:before, 
.ng-table th.sortable.sort-desc .sort-indicator:after {
    border-color:#FFF transparent;
}
.no-pager .ng-table-pager {
    display: none !important;
}
.tab-pane .no-pager {
    margin:15px 0 20px;
}
.table>thead>tr>th {
    border-bottom:0 none;
    border-top:0 none;
    padding:0 0 5px;
}
.table>thead>tr>th span {
    white-space: nowrap;
    padding: 0 30px;
}
.ng-table th.sortable.sort-desc, 
.ng-table th.sortable.sort-asc {
    text-shadow:0 0 0;
    background:transparent;
}
.ng-table-group-selector:before, 
.ng-table-group-selector:after, 
.filter:before, .filter:after {
    content:initial;
}

.nav-tabs.nav-justified>li>a {
    margin: 0;
}

/* ROWS RECORDS */
.accordion.panel-group .panel {
    margin-bottom: 10px;
    border-radius: 0;
    /*border: 1px solid transparent;*/
}
.accordion.panel-group .panel-heading {
    padding: 18px 15px;
    border-radius: 0;
    display: block;
}
.accordion.panel-group .panel-heading.toDelete {
    opacity:0.4;
}
.panel-heading, .accordion.panel-group .panel-heading .fa, .panel-body .widget-body:not(.bordered) {
    cursor: pointer;
}
.panel-heading.empty {
    cursor: inherit;
}

.panel-heading.no-click {
    cursor: default;
}
.accordion.panel-group .panel-body {
    padding: 15px 0 5px;
    background: rgba(255, 255, 255,0.1);
}

.accordion.panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: none;
}

.record {
    line-height: 18px;
    margin: 8px 0;
}
.empty .record>div {
    white-space: normal;
}
.record>div {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.record>.keyword-column {
    white-space: normal;
}

.record>.keyword-column .delete-icon {
    display:none;
}

.row-bordered {
    padding: 10px 10px 10px 0;
    border: 2px solid #606060;
    line-height: 18px;
    margin: 15px 0;
}

.row-bordered.scrolling {
    padding: 15px 0 0;
    max-height: 250px;
    overflow-y:scroll;
    background:rgba(0,0,0,0.7);
}

.button-row {
    margin-top: 15px;
}

.row-top {
    padding:10px 10px 20px;
    border-bottom: 2px solid #606060;
    margin: 10px 0
}

.row-top button {
    width: 100%;
}

.row-top h3 {
    margin: 0;
    line-height: 40px;
}

.record .bullet {
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    width: 8px;
    height: 8px;
}

.panel-default > .panel-heading.record-draft, .panel-section-content .record-draft .panel-section-record-row {
    background-color: rgba(210,33,42,0.3);
}

.panel-default > .panel-heading.record-active, .panel-section-content .record-active .panel-section-record-row {
    background-color: rgba(90,150,10,0.3);
}

.record-active .highligted-text, .highligted-text-green {
    color: #afd774
}

.record-draft .highligted-text, .highligted-text-red {
    color:#ff323c;
}

.input-group {
    margin: 0;
}

.record-title {
    margin:15px 0;
    background: rgba(230, 230, 230, 0.4);
    padding: 15px 15px 5px;
}

/** panel with sections **/
.panel-section-record-header {
    background: rgba(255,255,255,0.7);
    color: #555;
    font-weight: bold;
    text-transform: uppercase;
}

.accordion.panel-group .panel-section-body {
    padding: 10px;
}

.panel-group .panel-section-body .panel-heading {
    padding: 0 15px;
}

.panel-group .panel-section-body .panel, .panel-section-records .record {
    margin: 0;
}

.panel-group .panel-section-body .panel-section-header, .panel-section-record {
    margin: 5px 0 0 0;
}

.panel-group .panel-section-records .record {
    padding: 10px 0px;
    line-height: 26px;
}

.panel-group .panel-section-record-header .panel-heading, .panel-group .panel-section-header .panel-heading {
    cursor: default;
    padding: 10px 15px;
}

.panel-group .panel-section-header .panel-heading {
    background-color: rgba(230, 230, 230, 0.4);
    text-transform:uppercase;
    font-weight:bold;
}

.panel-section-record-row {
    background-color: rgba(96, 96, 96, 0.5);
}

.panel-section-record-row .panel-heading-campaign {
    background: none;
}

.panel-section-records .fa-wrap {
    display: none;
}

.panel-section-records .panel-section-record-row:hover .fa-wrap {
    display: inline;
}

.panel-section-records .panel-section-record-row:hover .fa-wrap i {
    position: relative;
    z-index: 10;
}

.panel-section-records .panel-section-record-row .nowrap {
    white-space: nowrap;
}

/** campaign communication **/
.create-communication-modal .btn-default {
    cursor: pointer;
    min-height: 213px;
    font-size:16px;
}

.create-communication-modal .form-group {
    margin-bottom: 30px;
}

.create-communication-modal .form-group.last-row {
    margin-bottom: 15px;
}

.panel-heading.non-expandable {
    cursor: default;
}

.panel-heading.non-expandable .fa-angle-down, .panel-heading.non-expandable .fa-angle-up {
    display: none;
}


/** widget **/
.widget {
    margin-bottom: 10px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.widget-body {
    padding: 0 15px;
    line-height: 74px;
}

.widget-body.bordered {
    border:2px solid #606060;
    line-height: 70px;
}

.widget-footer {
    padding: 10px 15px;
}

.widget-bg {
    background-color: rgba(96, 96, 96,0.5);
}

.widget div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-header {
    min-height: 60px;
}

.modal-full-screen {
    width:100%;
    margin:0;
    height:100%;
}

.modal-full-screen .modal-content {
    height: 100%;
}
/**
 RESPONSIVINESS
*/

/* Responsive: Portrait tablets and up */

@media screen and (min-width: 768px) {

    .footer {
        padding-left: 0;
        padding-right: 0;
    }

    /* Space out the masthead */
    .header {
        margin-bottom: 30px;
    }

    #page-wrapper img.momentum-bg {
        left: 250px;
    }

    .login .container {
        height: 100%;
        position: relative;
    }

    .vertical-align-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        display: table;
    }
}

@media screen and (max-width:992px) {

    .widget-body {
        line-height: inherit;
    }

    .panel-body .widget-body:not(.bordered) {
        padding:24px 15px;
    }

    .widget-body.bordered {
        border:0 none;
        margin: 0 -15px;
    }

    .row-top>div:not(:first-child) {
        margin:10px -25px 0;
    }

    .row-top h3 {
        text-align: center;
    }

    .panel-collapse .btn {
        display: block;
        width: 100%;
    }

    .create-communication-modal .btn-default {
        min-height:auto;
    }

    .create-communication-modal .form-group {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 767px) {
    
    .app-version {
        display: none;
    }

    img.momentum-bg {
        left: 50%;
        margin-left: -512px; /* yay 50% */
    }
    
    .keyword-add-button  {
        top: 28px;
    }

    .panel-default .panel-heading .fa-wrap, .panel-collapse .widget-body .fa-wrap {
        display: inline;
    }

}
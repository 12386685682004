.multi-select {
    --rmsc-border-radius: 0;

    .go445362013, 
    .dropdown-content {
        z-index: 3;
    }

    [placeholder="Search"] {
        color: black;
    }
}

//
// -- auth

@import 'vars';

.authentication {
	display: block;
	height: 80vh;
	margin: 0 auto;
	width: 400px;
}

:root {
	--amplify-text-md: 1.8rem;
	--amplify-text-sm: 1.4rem;
	--amplify-text-xs: 1rem;
	--amplify-primary-color: #d2212a;
	--amplify-background-color: lightgrey;
}
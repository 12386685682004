//
// -- controls (buttons, fields and the like)

//@import 'compass';
@import 'vars';

@mixin inlineButton(){
	@include border-radius(4px);
	border: 1px solid $inlineButtonBorderColor;
	padding: 1px 6px;
	margin-left: 2px;
	display: inline-block;
	color: $inlineButtonBorderColor;
	
	&:hover { 
		text-decoration: none; 
		background: rgba(white, .1);
	}
}

button {
	background: none;
	outline: none;
	border: none;

	&.remove {
		height: 0;
		padding: 0;
	}
}

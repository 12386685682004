//
// -- vars

// smartlists

$inlineButtonBorderColor: white;

$color_mercury_40_approx: rgba(230,230,230,0.4);
$white: #fff;
$black_20: rgba(0,0,0,0.2);
$pale_canary: rgb(255, 255, 153);
$color_fair_pink_approx: #fee;
$color_storm_dust_approx: #666;
$color_mine_shaft_approx: #333;
$color_mercury_approx: #e6e6e6;
$color_silver_chalice_approx: #adadad;
$color_curious_blue_approx: #29d;

//
// -- smartlists

//@import 'compass';
@import 'vars';

// styles

.smart-list-table {
	overflow-y: hidden;
	
	.table-responsive { 
		overflow-y: hidden;
	}
}
	
.preview-note { 
	display: block;
	text-align: center;
	position: relative;
	margin: 0 0 20px 0;
	
	a { margin-left: 4px; }

	&.empty-list {
	margin-top:20px;
	}
}

%extend_1 {
	position: absolute;
	left: 0;
	top: 0;
}

.row-smartListNav {
	padding: 0 15px;
	
	.nav-tabs {
		border: none;
		background: $color_mercury_40_approx;
		padding: 0;
		
		> li {
			padding: 0;
			margin-bottom: 0;
			
			> a, button {
				border-radius: 0;
				border: none;
				outline: none;
				background-color: transparent;
				width: 100%;
				display: inline-block;
				padding: 5px 0;
				
				&:hover, &:focus {
					border: none;
					border-radius: 0;
					color: $white;
					background: $black_20;
				}
			}
			&.active > a, &.active > button {
				border: none;
				border-radius: 0;
				color: $white;
				background: $black_20;
			}
		}
	}
	.table-responsive {
		margin: 10px 0 20px;
		overflow-x:scroll;
		.table {
			margin-bottom:0;
		}
	}
	.ng-table-counts {
		margin-bottom: 22px;
	}
	.btn-default.active {
		color: $color_mine_shaft_approx;
		background-color: $color_mercury_approx;
		border-color: $color_silver_chalice_approx;
	}
	.ng-table th.sortable .sort-indicator {
		white-space: nowrap;
	}
}
.row-subscription-filter {
	padding: 10px;
	&.row-default {
		padding: 0 0 5px 0;
	}
	.empty {
		.styled-select {
			background-color: $pale_canary;
		}
		.form-control {
			background-color: $pale_canary;
		}
	}
}
.table-smartlist {
	position: relative;
	thead {
		z-index: 1;
		text-transform:uppercase;
	}
	tbody {
		margin:40px 0 0;
	}
	th {
		min-width: 200px;
	}
	td {
		min-width: 200px;
	}
}
.title-smartlist {
	background: transparent;
	border: 1px solid transparent;
	text-align: center;
	width: 80%;
	&:hover {
		border: 1px solid $color_storm_dust_approx;
	}
}
.title-smartlist-readonly {
	border: 1px solid transparent;
	&:hover {
		border: 1px solid transparent;
	}
}
.loading-icon {
	width: 50px;
	height: 50px;
	border: solid 6px transparent;
	border-top-color: $white;
	border-left-color: $white;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	animation: loading-bar-spinner 600ms linear infinite;
	position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
}
.loading-overlay {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.35);
    z-index: 1060;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    0% { opacity: 0; }
    30%   { opacity: 0; }
    100%  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    30%   { opacity: 0; }
    100%  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    30%   { opacity: 0; }
    100%  { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    0% { opacity: 0; }
    30%   { opacity: 0; }
    100%  { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    0% { opacity: 0; }
    30%   { opacity: 0; }
    100%  { opacity: 1; }
}

.smartListRuleBtn .fa {
	font-size: 2.6em;
	&:last-child {
		margin-left: 10px;
	}
}
.form-smartlist .form-horizontal {
	border-bottom: 1px solid $color_fair_pink_approx;
	&.empty {
		border: none;
	}
	input {
		border: none;
		outline: none;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: none;
	}
	select {
		cursor: pointer;
	}
}

.nav-tabs.nav-justified > .active > a {
	border: none;
	&:focus {
		border: none;
	}
	&:hover {
		border: none;
	}
}
.multiselect-smartlist .multiSelect > button {
	width: 100%;
	border: none;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: none;
}

.multiSelect > button {
	background:none transparent;
	text-align:left;
	font-size:15px;
}

.multiSelect > button:hover {
	background:none;
}

.multiSelect .caret {
	display:none;
}

.multiSelect .multiSelectItem:hover, .multiSelect .multiSelectGroup:hover {
	background-image:none !important;
	background:#ccc;
}

.multiSelect .multiSelectItem .acol label {
	margin:4px 0;
}

.smartlist-rules {
	display: flex;
}

.smartlist-rules-row-number {
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	padding: 10px;
	border-right: none;
	background: #aaa;
	color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
	margin:-10px 0;
	border-right:2px solid #606060;
}

.smartrule-input {
	margin: 15px 0 0;
}

.smartrule-input .form-control span {
	color: #41c7fb;
}

.smartrule-input-field .textinput {
	position: relative;
  font-family: 'Droid Sans', sans-serif;
  height: auto;
  text-transform: uppercase;
}

.smartrule-input-field div.textinput {
	padding: 0;
}

.smartrule-input-field input.textinput {
  width: 90% !important;
  outline: none;
  border: none;
  box-shadow: none;
}

.smartrule-input-field .textinput:before {
	position: absolute;
	bottom: 10px;
	right: 10px;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 20px;
}

.smartrule-input-field .textinput.fa-check:before {
	color: green;
}

.smartrule-input-field .textinput.fa-warning:before {
	color: #ddd51c;
}

.smartrule-input-field .textinput.fa-close:before {
	color: red;
}

.smartrule-input-toggle input {
	vertical-align: -2px;
}

//
// queries

@media screen and(max-width: 767px) {
	.row-smartListNav .table-responsive {
		border:0 none;
	}

	.smartlist-rules {
		display: block;
	}

	.smartlist-rules-row-number {
		margin-bottom: 0;
		border: 2px solid #606060;
		border-bottom: none;
		line-height: 20px;
	}

	.smartlist-rules-row-input {
		margin-top: 0;
	}
}

.radio-button {
    margin-top: 0.5rem; 
    display: block;
    @media (min-width: 767px) {
        display: inline-block;
    }
    
    input[type="radio"] {
        visibility: hidden;
        width: 0;
        margin: 0;
    }
    
    label {
        padding: 1.4rem 3rem;
        background-color: #FFFDFD;
        color: #999;
        line-height: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        cursor: pointer;

        &.selected {
            background-color: #d2212a;
            color: #fff;
        }

        &:first-child {
            margin-left: 0rem;
            @media (min-width: 767px) {
                margin-left: 4rem;
            }
        }
    }
}

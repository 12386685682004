.sort-icon {
	cursor: pointer;
	display: inline-block;
	font-size: 2rem;
	padding: 0;
}

.up-arrow {
	display: inline-block;
	transform: rotate(90deg);
}

.down-arrow {
	display: inline-block;
	transform: rotate(-90deg) translateX(-4px) translateY(0px);
}

/*!
 * Start Bootstrap - Momentum 2.0 skin
 */

 html, body {
    height: 100%;
    width: 100%;
 }

body {
    background:#111111 url('../images/momentum-bg.png') no-repeat center center fixed;
    background-size: cover;
    color: #fff;
    font-family: 'Droid Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
}

#wrapper {
    width: 100%;
    position: relative;
}

#page-wrapper {
    padding: 65px 15px 0;
}

/* navbar */
.navbar {
    min-height: 65px;
    border: 0 none;
    background:#222;
    width: 230px;
}

.navbar-brand {
    background: url(../images/OlsonMomentum.png) center center no-repeat;
    width: 210px;
    background-size: 210px;
    background-position: 10px;
}

.login .navbar-brand {
    background: url(../images/OlsonLogo.png) center center no-repeat;
    width: 122px;
}

.login .navbar {
    width: 100%;
}

.navbar-brand  {
    height: 65px
}

.img-responsive.logo {
    height: 100%;
}

.momentum-logo {
    margin: 20px 0 0;
}

.navbar-main .my-ccount,
.login .contact-us {
    background-color: transparent;
    height: 65px;
    font-size: 20px;
    border-left: 1px solid #646464;
}

.navbar-main .my-ccount  {
    padding: 25px 30px 25px 20px;
}

.login .contact-us {
    padding: 20px 25px 20px 20px;
}

button.navbar-toggle {
    margin-top:11px;
}

.navbar-inverse .navbar-toggle {
    border:0 none;
}

.navbar .dropdown-menu {
    border-radius: 0;
    width: 100%;
    padding: 0;
    margin: 0;
}

.navbar .dropdown-menu>li>a {
    padding: 22px 20px;
}
.navbar .dropdown-menu .divider {
    margin: 0;
}
.btn-group.open {
    background-color: #606060;
}
.btn-group.open .my-ccount  {
    border-left: 1px solid #606060;
}

.navbar-right li>a:focus,
.navbar-right li>a:hover
{
    text-decoration: none;
    background-color: transparent;
}

/* sidebar-nav */
.sidebar-nav .nav>li>a, .sidebar-nav .nav>li>button {
    padding: 10px 15px;
    background-color: #f4f4f4;
    color:#555;
    font-size:15px;
    line-height: 30px;
    min-height: 54px;
    cursor: pointer;
    -webkit-transition: background-color 300ms ease;
    -moz-transition: background-color 300ms ease;
    -o-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    width: 100%;
    text-align: left;
}

.sidebar-nav .nav>li>a:hover {
    background-color: #e9e9e9;
}

.sidebar-nav .nav li.active:not(.parent)>a {
    text-decoration: none;
    background-color: #fff;
    color:#d2212a;
    border-right:7px solid #d2212a;
}

.sidebar .fa {
    vertical-align: top;
}

.sidebar li.active .fa.arrow {
    padding-right: 0;
}

.sidebar .sidebar-nav.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
    background-color: #fff;
}

.sidebar .sidebar-search {
    padding: 15px;
}

.sidebar ul li {
    border-bottom: 1px solid #b8b8b8;
}

.sidebar ul li a.active {
    background-color: #fff;
    color:#d2212a;
}

.sidebar .arrow {
    float: right;
    line-height: 35px;
}

.sidebar .fa.arrow:before {
    content: "\f107";
}

.sidebar .active>a>.fa.arrow:before {
    content: "\f106";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: 0!important;
}

.sidebar .nav-second-level li a {
    padding-left: 57px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

.sidebar .nav-text {
    display: inline-block;
    width:59%;
    line-height: 1.5;
}

@media(min-width:768px) {
    .sidebar {
        z-index: 1;
        position: absolute;
        width: 230px;
        margin-top: 73px;
        top: 0;
        height: calc(100vh - 73px);
        overflow-y: scroll;
    }

    .sidebar.fixed {
        position: fixed;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}


/* styles from original theme */

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.chat-panel .panel-body {
    height: 350px;
    overflow-y: scroll;
}


.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

.dataTables_wrapper {
    position: relative;
    clear: both;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee!important;
}

.show-grid {
    margin: 15px 0;
}

.huge {
    font-size: 40px;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}
/* end styles from original theme */



.header-icon-font {
    font-size: 30px!important;
}

div .workflow-list-buttons
{
    display: none;
}

div:hover .workflow-list-buttons
{
    display: block;
}


/* Multi Select - TODO */
.multi-select {
  --rmsc-primary: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-background: #fff;
  --rmsc-spacing: 10px;
  --rmsc-border-radius: 4px;
  --rmsc-height: 38px;
}

.multi-select label, .multi-select span {
    color: #555;
}

@media(min-width:768px) {
    #page-wrapper {
        position: static;
        margin: 0;
        padding: 20px 30px;
        border-left: 230px solid #f4f4f4;
        min-height: 100vh;
    }
    #page-wrapper.fixed {
        margin:0;
    }

    .navbar {
        margin-bottom: 0;
        top: 0;
    }

    .navbar.fixed {
        position: fixed;
    }

    .navbar-brand {
        height: 73px;
    }

    .navbar-main .my-ccount,
    .login .contact-us {
        height: 83px;
    }

    .login .contact-us {
        padding: 29px 25px 28px 20px;
    }

    .my-ccount:hover,
    .my-ccount:focus {
        color: #fff;
    }

}

@media screen and (max-width: 767px) {

    .login .container {
        padding-top: 65px;
    }

    .navbar {
        width: 100%;
        position: fixed;
    }

}
